import XMLBeautifyComponent from "@jordibosch20/software_tools_package/dist/pages/XMLBeautify.component.js"
import styles from "./App.module.css";
import { getXMLBeautifyString } from "@jordibosch20/software_tools_package/dist/utils/xml-beautify.js";
import { useAnalytics } from './useAnalytics';

function App() {
  useAnalytics();
  return (
    <div className={styles.AppWrapper}>
      <div className={styles.App}>
        <XMLBeautifyComponent title={"XML beautify/minify"} f={getXMLBeautifyString}></XMLBeautifyComponent>
      </div>
      <div className={styles.text}>
        <h3>Introduction</h3>
        <p>When it comes to working with XML, readability is key, especially during the debugging process. XML files often undergo minification and tabs removal to improve network performance, which can make them difficult to read and debug. The ability to quickly reformat these files into a more digestible format can significantly ease the process of troubleshooting and error identification.</p>
        <h3>Introducing Our Solution: The Instant XML Beautifier</h3>
        <p>Imagine converting a minified XML document into a beautifully formatted and easy-to-read version with just a simple click. That's the convenience our XML beautifier brings to the table. Say goodbye to the frustration of navigating through dense, compacted code.</p>
        <h4>Key Features:</h4>
        <ul>
        <li><strong>One-Click Transformation:</strong> Paste your XML, press beautify, and there you have it &ndash; pristine, understandable code.</li>
        <li><strong>Customization Options:</strong> Adjust the output to meet your coding preferences, including indentation and line breaks.</li>
        </ul>
        <h3>How to Utilize the Instant XML Beautifier</h3>
        <ol>
        <li><strong>Copy and Paste:</strong> Begin by copying your condensed or cluttered XML code into our beautifier's input field.</li>
        <li><strong>Adjust Settings:</strong> Select your desired formatting settings, like indentation style.</li>
        <li><strong>Beautify:</strong> With one click, convert your code into a neat, optimized format.</li>
        <li><strong>Examine and Implement:</strong> Review the beautified code for any necessary tweaks, then integrate it back into your project with ease.</li>
        </ol>
        <h3>Is It Safe to Paste Confidential Code?</h3>
        <div>
        <div><span>Rest assured, your pasted content remains secure within your browser. Our platform operates solely on the client side, without transmitting any data to servers, ensuring your information never leaves your device.</span></div>
        </div>
        <p><strong>Can the beautifier handle large XML files?</strong><br />A: Definitely! Our solution is built to accommodate XML files of any size, from brief fragments to extensive documents, facilitating smooth processing and optimization, the bottleneck is in the RAM your device can handle</p>
          <div className={styles.links}>
          <h3>Other similar resoures that could be helpful</h3>

          <li><a href="https://check-json.com">JSON format checker</a></li>
          <li><a href="https://check-xml.com">XML Checker</a></li>
          <li><a href="https://check-yaml.com">YAML Checker</a></li>
          <li><a href="https://formatter-json.com">JSON formatter</a></li>
          <li><a href="https://add-delimiters.com">Add delimiters to sentences</a></li>
          <li><a href="https://convert-base.com">Convert base</a></li>
          <li><a href="https://convert-binary.com">Convert text/file to binary</a></li>
          <li><a href="https://convert-hexadecimal.com">Convert text/file to hexadecimal</a></li>
          <li><a href="https://convert-base64.com">Convert text/file to base64</a></li>
          <li><a href="https://css-beautify.com">CSS beautifier</a></li>
          <li><a href="https://html-beautify.com">HTML beautifier</a></li>
          <li><a href="https://javascript-beautify.com">Javascript beautifier</a></li>
          <li><a href="https://xml-beautify.com">XML beautifier</a></li>
          <li><a href="https://extract-email.com">Email extractor</a></li>
          <li><a href="https://extract-phone.com">Phone extractor</a></li>
          <li><a href="https://extract-url.com">URL extractor</a></li>
          <li><a href="https://json-2-csv.com">JSON to CSV online converter</a></li>
          <li><a href="https://json-to-yaml.com">JSON to YAML online converter</a></li>
          <li><a href="https://software-kit.com">Software tools</a></li>
          <li><a href="https://svg2css.com">SVG to CSS background </a></li>
          <li><a href="https://trim-text.com">Text trimmer</a></li>
          <li><a href="https://url-coder.com">URL encoder/decoder</a></li>
          <li><a href="https://yaml2json.com">YAML to JSON</a></li>
          <li><a href="https://csv-to-json.com">CSV to JSON</a></li>
          <li><a href="https://sort-text.com">Sort text</a></li>
          <li><a href="https://convert-casing.com">Convert casing</a></li>
          <br></br>
          <br></br>
          <br></br>
          <span>In case you have any <b>suggerence or found any bug</b> in our tools, please, feel free to email us at statisticskingdom @ gmail.com (without the spaces) and we will take care of it.</span>
        </div>
      </div>
    </div>
  );
}

export default App;